import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import Img from 'gatsby-image/withIEPolyfill';

const thresholdFn = Math.round;

const getColSpan = (r, min) => (r >= 1 ? Math.min(thresholdFn(r), min) : 1);
const getRowSpan = (r, min) => (r < 1 ? Math.min(thresholdFn(1 / r), min) : 1);

const StyledItem = styled.div`
  grid-row-end: span ${({ ratio }) => getRowSpan(ratio, Infinity)};
  grid-column-end: span ${({ ratio }) => getColSpan(ratio, Infinity)};
  @media (max-width: 726px) {
    & {
      grid-column-end: span ${({ ratio }) => getColSpan(ratio, 2)};
    }
  }
  @media (max-width: 490px) {
    & {
      grid-column-end: span 1;
    }
  }
  a {
    width: 100%;
    background: #fff;
    display: inline-block;
    padding: 10px 10px 0px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    transition: transform 0.15s linear;
    &:after {
      font-family: 'Rock Salt', sans-serif;
      line-height: 3rem;
      content: attr(title);
    }
    &:hover {
      transform: scale(1.05);
      position: relative;
      z-index: 5;
    }
  }
`;

const Item = ({ featured, id, title }) => (
  <StyledItem ratio={featured.sharp.fluid.aspectRatio}>
    <Link to={`/series/${id}`} title={title}>
      <Img fluid={featured.sharp.fluid} objectFit={'cover'} alt={title} />
    </Link>
  </StyledItem>
);

Item.propTypes = {
  id: PropTypes.string,
  featured: PropTypes.object,
  title: PropTypes.string,
};

export default Item;
