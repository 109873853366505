import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Grid from '../components/home/Grid';

const IndexPage = ({ path, data }) => {
  const intl = useIntl();
  return (
    <Layout path={path}>
      <SEO title={intl.formatMessage({ id: 'Home' })} />
      <Grid items={data.allMarkdownRemark.edges} />
    </Layout>
  );
};

IndexPage.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object,
};

export default IndexPage;

export const data = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            id
            featured {
              sharp: childImageSharp {
                fluid(quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
