import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Item from './Item';
import order from '../../../data/series/home.yaml';

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: row dense;
  align-items: center;
  justify-content: center;
`;

const getPhotos = edges =>
  order
    .map(x => edges.find(y => y.node.frontmatter.id === x.id))
    .filter(x => x !== null);

const Grid = ({ items }) => (
  <StyledGrid>
    {getPhotos(items).map((x, idx) => (
      <Item
        key={idx}
        title={x.node.frontmatter.title}
        featured={x.node.frontmatter.featured}
        id={x.node.frontmatter.id}
      />
    ))}
  </StyledGrid>
);

Grid.propTypes = {
  items: PropTypes.array,
};

export default Grid;
